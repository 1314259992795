import React, { useState, useRef, useEffect } from "react";
import { Handle, Position, useReactFlow } from "reactflow";
import { FaImage, FaFileAlt } from "react-icons/fa";
import { RiDeleteBin5Line } from "react-icons/ri";
import { message } from "antd";
import "reactflow/dist/style.css";
import { uploadMedia } from "../api/MediaID";

const hfStyle = {
  backgroundColor: "white",
  top: 8,
  padding: 12,
  borderStyle: "solid",
  borderColor: "green",
  borderWidth: 5,
};
const hfStylebutton = {
  backgroundColor: "transparent", // Ensures a truly transparent background
  top: 60,
  right: -70,
  padding: 8,
  borderStyle: "solid",
  borderColor: "#008000", // Light green color for the border (adjust as needed)
  borderWidth: 4, // Adjust the border width to your preference
  width: 100,
  height: 10,
  borderRadius: 10,
};

const rfStyle = {
  backgroundColor: "white",
  left: 650,
  top: 10,
  padding: 8,
  borderStyle: "solid",
  borderColor: "green",
  borderWidth: 5,
};

function MediaNode({ id, data }) {
  const [type, setType] = useState("image");
  const [bodyText, setBodyText] = useState("");
  const [footer, setFooter] = useState("");
  const [buttonTexts, setButtonTexts] = useState([""]);
  const { setNodes } = useReactFlow();
  const fileInputRef = useRef(null);
  const [file, setFile] = useState(null);
  const [show, setShow] = useState(false);
  const [headerText, setHeaderText] = useState("");
  const [mediaId, setMediaId] = useState(null);

  // Generate dynamic JSON payload
  const generateMessagePayload = () => {
    const buttons = buttonTexts.map((text, index) => ({
      type: "reply",
      reply: { id: `button-${index + 1}`, title: text },
    }));

    const payload = {
      messaging_product: "whatsapp",
      recipient_type: "individual",
      to: "mobile_no",
      type: "interactive",
      interactive: {
        type: "button",
        header:
          type === "text"
            ? { type: "text", text: headerText }
            : type === "image" && file
            ? { type: "image", image: { id: mediaId } }
            : type === "document" && file
            ? {
                type: "document",
                image: { id: mediaId },
              }
            : type === "video" && file
            ? {
                type: "video",
                image: { id: mediaId },
              }
            : null,

        body: { text: bodyText },
        footer: { text: footer },
        action: { buttons },
      },
    };
    return payload;
  };

  // Trigger change whenever relevant state updates
  const handleChange = () => {
    if (data.onChange) {
      const messagePayload = generateMessagePayload();
      data.onChange(id, "payload", messagePayload);
    }
  };

  useEffect(() => {
    handleChange();
  }, [type, bodyText, footer, buttonTexts, file]);

  const handleTypeChange = (e) => {
    setType(e.target.value);
    if (e.target.value !== "image" && e.target.value !== "document") {
      setFile(null);
    }
  };

  const handleAddButton = () => {
    if (buttonTexts.length < 3) {
      setButtonTexts((prev) => [...prev, ""]);
    } else {
      message.warning("You can only add up to 3 buttons.");
    }
  };

  const handleDeleteButtonText = (index) => {
    if (index === 0) {
      message.warning("You cannot delete the first button."); // Prevent deletion of the first button
      return;
    }
    if (data.onChange) {
      data.onChange(id, "payload", null);
    }
    setButtonTexts((prev) => prev.filter((_, i) => i !== index));
  };

  const handleNodeDelete = () => {
    setNodes((nodes) => nodes.filter((node) => node.id !== id));

    if (data.onChange) {
      data.onChange(id, "payload", null);
    }
  };

  const MAX_IMAGE_SIZE = 5 * 1024 * 1024; // 5 MB for images
  const MAX_VIDEO_SIZE = 16 * 1024 * 1024; // 16 MB for videos
  const MAX_DOC_SIZE = 100 * 1024 * 1024; // 100 MB for documents

  const handleFileChange = async (event) => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      const allowedImageTypes = ["image/jpeg", "image/png"];
      const allowedDocTypes = [
        "text/plain",
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/vnd.ms-powerpoint",
        "application/vnd.openxmlformats-officedocument.presentationml.presentation",
        "application/pdf",
      ];
      const allowedVideoTypes = ["video/3gp", "video/mp4"];

      // Validate image
      if (type === "image" && !allowedImageTypes.includes(selectedFile.type)) {
        alert("Invalid image type. Please upload a .jpeg or .png file.");
        fileInputRef.current.value = ""; // Clear the file input
        return;
      }

      // Validate document
      if (type === "document" && !allowedDocTypes.includes(selectedFile.type)) {
        alert("Invalid document type. Please upload a valid file format.");
        fileInputRef.current.value = ""; // Clear the file input
        return;
      }

      // Validate video
      if (type === "video" && !allowedVideoTypes.includes(selectedFile.type)) {
        alert("Invalid video type. Please upload a .3gp or .mp4 file.");
        fileInputRef.current.value = ""; // Clear the file input
        return;
      }

      // Check file size for images
      if (type === "image" && selectedFile.size > MAX_IMAGE_SIZE) {
        alert(
          "Image size exceeds the 5MB limit. Please choose a smaller file."
        );
        fileInputRef.current.value = ""; // Clear the file input
        return;
      }

      // Check file size for videos
      if (type === "video" && selectedFile.size > MAX_VIDEO_SIZE) {
        alert(
          "Video size exceeds the 16MB limit. Please choose a smaller file."
        );
        fileInputRef.current.value = ""; // Clear the file input
        return;
      }

      // Check file size for documents
      if (type === "document" && selectedFile.size > MAX_DOC_SIZE) {
        alert(
          "Document size exceeds the 100MB limit. Please choose a smaller file."
        );
        fileInputRef.current.value = ""; // Clear the file input
        return;
      }

      setFile(selectedFile); // Set the selected file if all validations pass
    }
    if (selectedFile) {
      setFile(selectedFile);
      try {
        const mediaId = await uploadMedia(selectedFile);
        setMediaId(mediaId);
        alert("media id", mediaId);
        message.success("Media uploaded successfully");
      } catch (error) {
        message.error(error.message);
      }
    }
  };

  return (
    <div
      className="relative bg-[#ffffff] rounded-[45px] p-7 w-[800px] shadow-2xl group hover:border-solid hover:border-[4px] hover:border-green-600"
      onMouseOver={() => setShow(true)}
      onMouseOut={() => setShow(false)}>
      <Handle
        type="target"
        position={Position.Left}
        id="interactiveReplyButtonsMessages"
        className="ml[10px]"
        style={hfStyle}
      />

      {show && (
        <button
          onClick={handleNodeDelete}
          className="absolute right-2 top-0 text-black text-lg rounded-full p-6 bg-white shadow-2xl hover:text-red-800">
          <RiDeleteBin5Line
            className="text-gray-600 hover:text-red-500"
            style={{ fontSize: 50 }}
          />
        </button>
      )}

      <div className="flex bg-[#def7ec] border-l-[20px] border-green-500 text-green-500 rounded-[20px] mt-1 mb-2 p-4 w-full">
        <FaImage className="mt-5 text-4xl" />
        <h3 className="text-center text-[40px] mt-2 ml-4">
          Interactive Reply Messages
        </h3>
      </div>

      <div className="bg-[#eae6df] border-[3px] border-solid border-green-600 rounded-[25px] p-3 mt-5">
        <label className="block p-4 text-3xl font-semibold text-gray-700 mb-3">
          Header
        </label>
        <select
          value={type}
          onChange={handleTypeChange}
          className="block w-full text-3xl p-9 rounded-lg border border-gray-300 shadow-sm 
             focus:ring-2 focus:ring-green-600 focus:border-green-600">
          <option value="image" className="text-gray-700  text-sm  ">
            🖼️ Image
          </option>
          <option value="text" className="text-gray-700 text-sm  ">
            💬 Text
          </option>
          <option value="document" className="text-gray-700 text-sm  ">
            📄 Document
          </option>
          <option value="video" className="text-gray-700 text-sm  ">
            🎬 Video
          </option>
        </select>
        {type === "text" && (
          <div className="block mt-11 text-3xl font-semibold text-gray-700 mb-3">
            Header Text (Optional)
            <input
              type="text"
              placeholder="Enter header text"
              value={headerText}
              onChange={(e) => setHeaderText(e.target.value)}
              className="text-center text-decoration-none rounded-2xl p-10 text-3xl w-full mt-4"
            />
          </div>
        )}

        {type === "video" && (
          <div className="mt-4">
            <input
              type="file"
              ref={fileInputRef}
              onChange={handleFileChange}
              accept=".3gp,.mp4"
              style={{ display: "none" }}
            />
            {file ? (
              <video
                controls
                src={URL.createObjectURL(file)}
                className="rounded-2xl mx-auto"
                style={{ maxWidth: "100%", maxHeight: "100%" }}
              />
            ) : (
              <button
                onClick={() => fileInputRef.current.click()}
                className="flex flex-col items-center justify-center p-4 border w-full h-[350px] border-gray-300 rounded-lg shadow-lg bg-white">
                <span className="text-6xl text-black">🎬</span>
                <p className="mt-2 text-3xl text-gray-500">Upload a video</p>
                <p className="mt-2 text-3xl text-gray-500">
                  File size exceeds the 16MB limit. Please choose a smaller
                  file.
                </p>
              </button>
            )}
          </div>
        )}

        {(type === "image" || type === "document") && (
          <div className="mt-4">
            <input
              type="file"
              ref={fileInputRef}
              onChange={handleFileChange}
              accept={
                type === "image"
                  ? ".jpeg,.jpg,.png"
                  : ".txt,.xls,.xlsx,.doc,.docx,.ppt,.pptx,.pdf"
              }
              style={{ display: "none" }}
            />
            {file ? (
              type === "image" ? (
                <img
                  src={URL.createObjectURL(file)}
                  alt="Uploaded"
                  className="rounded-2xl mx-auto"
                  style={{ maxWidth: "100%", maxHeight: "100%" }}
                />
              ) : (
                <p className="mt-2 text-3xl text-gray-500">
                  Document uploaded: {file.name}
                </p>
              )
            ) : (
              <button
                onClick={() => fileInputRef.current.click()}
                className="flex flex-col items-center justify-center p-4 border w-full h-[350px] border-gray-300 rounded-lg shadow-lg bg-white">
                <FaFileAlt className="text-6xl text-black" />
                <p className="mt-2 text-3xl text-gray-500">Upload a file</p>
                <p className="mt-2 text-3xl text-gray-500">
                  File size exceeds the allowed limit. Please choose a smaller
                  file.
                </p>
              </button>
            )}
          </div>
        )}

        <label className="block mt-11 text-3xl font-semibold text-gray-700 mb-3">
          Body Text (Required)
        </label>
        <textarea
          type="text"
          placeholder="Enter body text
          Max 1024 characters"
          value={bodyText}
          rows={4}
          cols={50}
          onChange={(e) => {
            const inputText = e.target.value;
            if (inputText.length <= 1024) {
              setBodyText(inputText);
            } else {
              message.warning("Body Text Limit: Maximum of 1024 characters.");
            }
          }}
          className="text-center rounded-2xl p-10 text-3xl w-full pt-28"
        />

        <label className="block mt-11 text-3xl font-semibold text-gray-700 mb-3">
          Footer Text (Optional)
        </label>
        <input
          type="text"
          placeholder="Enter footer text
          (Maximum 60 characters)"
          value={footer}
          onChange={(e) => setFooter(e.target.value)}
          className="text-center rounded-2xl p-10 text-3xl w-full "
        />

        <label className="block mt-11 text-3xl font-semibold text-gray-700 mb-3">
          Button Text (Required)
        </label>

        {buttonTexts.map((btnText, index) => (
          <div key={index} className="relative flex items-center w-full">
            <Handle
              type="source"
              position={Position.Right}
              id={`button_text_${index}`}
              className="ml[10px]"
              style={hfStylebutton}
            />
            <input
              type="text"
              placeholder={`Button Title ${index + 1}`}
              value={btnText}
              onChange={(e) => {
                const newText = e.target.value;
                if (newText.length <= 20) {
                  const newButtons = [...buttonTexts];
                  newButtons[index] = newText;
                  setButtonTexts(newButtons);
                } else {
                  message.warning(
                    "Button Label Text Limit: Maximum of 20 characters."
                  );
                }
              }}
              className="text-center rounded-2xl p-14 text-3xl h-[50px] w-full pr-16"
            />
            {index > 0 && ( // Show delete button only for additional buttons
              <button
                onClick={() => handleDeleteButtonText(index)}
                className="absolute left-6 top-1/2 transform -translate-y-1/2 bg-red-500 text-white w-10 h-10 rounded-full flex items-center justify-center">
                X
              </button>
            )}
          </div>
        ))}
        <button
          onClick={handleAddButton}
          className="bg-green-500 font-medium text-white text-2xl p-6 rounded-xl mt-6 mb-2 w-full">
          + Add Button
        </button>
      </div>
    </div>
  );
}

export default MediaNode;
