import React, { useState, useEffect } from "react";
import { Handle, Position, useReactFlow } from "reactflow";
import { FaBook } from "react-icons/fa";
import { RiDeleteBin5Line } from "react-icons/ri";
import { message } from "antd";
import "reactflow/dist/style.css";

const hfStyle = {
  backgroundColor: "white",
  top: 8,
  padding: 12,
  borderStyle: "solid",
  borderColor: "green",
  borderWidth: 5,
};

function CatalogNode({ id, data }) {
  const [bodyText, setBodyText] = useState("");
  const [footer, setFooter] = useState("");
  const [thumbnailProductId, setThumbnailProductId] = useState("");
  const [productOptions, setProductOptions] = useState([]); // Store the fetched product options
  const { setNodes } = useReactFlow();
  const [show, setShow] = useState(false);

  // Fetch product options from API
  const fetchProductOptions = async () => {
    try {
      const response = await fetch(
        "https://app.botlytix.com/getProductsFromCommerce",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            phone_number_id: "105581585784909", // Ensure correct format
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      console.log("Product data fetched successfully:", data);
      setProductOptions(data.products); // Assuming the response contains a 'products' array
    } catch (error) {
      console.error("Error fetching product options", error);
    }
  };

  useEffect(() => {
    fetchProductOptions();
  }, []);

  // Generate dynamic JSON payload based on the input
  const generateMessagePayload = () => {
    const payload = {
      messaging_product: "whatsapp",
      recipient_type: "individual",
      to: "<TO>", // Replace with actual recipient phone number
      type: "interactive",
      interactive: {
        type: "catalog_message",
        body: {
          text: bodyText,
        },
        action: {
          name: "catalog_message",
          parameters: {
            thumbnail_product_retailer_id: thumbnailProductId, // Thumbnail product ID
          },
        },
        footer: {
          text: footer,
        },
      },
    };
    return payload;
  };

  // Trigger change whenever relevant state updates
  const handleChange = () => {
    if (data.onChange) {
      const messagePayload = generateMessagePayload();
      data.onChange(id, "CatalogNodePayload", messagePayload);
    }
  };

  useEffect(() => {
    handleChange();
  }, [bodyText, footer, thumbnailProductId]);

  const handleNodeDelete = () => {
    setNodes((prevNodes) => prevNodes.filter((node) => node.id !== id));
    if (data.onChange) {
      data.onChange(id, "CatalogNodePayload", null); // Notify parent of text removal
    }
  };

  return (
    <div
      className="relative bg-[#ffffff] rounded-[45px] p-7 w-[800px] shadow-2xl group hover:border-solid hover:border-[4px] hover:border-green-600"
      onMouseOver={() => setShow(true)}
      onMouseOut={() => setShow(false)}>
      <Handle
        type="target"
        position={Position.Left}
        id="catalogMessages"
        style={hfStyle}
      />

      {show && (
        <button
          onClick={handleNodeDelete}
          className="absolute right-2 top-0 text-black text-lg rounded-full p-6 bg-white shadow-2xl hover:text-red-800">
          <RiDeleteBin5Line
            className="text-gray-600 hover:text-red-500"
            style={{ fontSize: 50 }}
          />
        </button>
      )}

      <div className="flex bg-[#def7ec] border-l-[20px] border-green-500 text-green-500 rounded-[20px] mt-1 mb-2 p-4 w-full">
        <FaBook className="mt-5 text-4xl" />
        <h3 className="text-center text-[40px] mt-2 ml-4">Catalog Message</h3>
      </div>

      <div className="bg-[#eae6df] border-[3px] border-solid border-green-600 rounded-[25px] p-3 mt-5">
        <label className="block p-4 text-3xl font-semibold text-gray-700 mb-3">
          Body Text (Required)
        </label>
        <textarea
          value={bodyText}
          onChange={(e) => setBodyText(e.target.value.slice(0, 1024))}
          placeholder="Enter body text - Maximum 1024 characters"
          rows={4}
          cols={50}
          className="text-center rounded-2xl p-10 text-3xl w-full pt-28"
        />
        <p className="text-gray-500 text-right">
          {bodyText.length}/1024 characters
        </p>

        <label className="block mt-11 text-3xl font-semibold text-gray-700 mb-3">
          Footer Text (Optional)
        </label>
        <input
          value={footer}
          onChange={(e) => setFooter(e.target.value.slice(0, 60))}
          placeholder="Enter footer text - Maximum 60 characters"
          className="text-center rounded-2xl p-10 text-3xl w-full"
        />
        <p className="text-gray-500 text-right">
          {footer.length}/60 characters
        </p>

        <label className="block mt-11 text-3xl font-semibold text-gray-700 mb-3">
          Thumbnail Product (Optional)
        </label>
        <select
          value={thumbnailProductId}
          onChange={(e) => setThumbnailProductId(e.target.value)}
          className="text-center rounded-2xl p-10 text-3xl w-full">
          <option value="" className="text-gray-700 text-[10px] pt-5">
            Select Product
          </option>
          {productOptions.map((product) => (
            <option
              key={product.retailer_id}
              value={product.retailer_id}
              className="text-gray-700 text-[10px] pt-5">
              {product.product_name}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}

export default CatalogNode;
