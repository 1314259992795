import React, { useState, useEffect } from "react";
import { Handle, Position, useReactFlow } from "reactflow";
import { RiDeleteBin5Line } from "react-icons/ri";
import "reactflow/dist/style.css";
import { FaRegPaperPlane } from "react-icons/fa";

const hfStyle = {
  backgroundColor: "white",
  top: 8,
  padding: 12,
  borderStyle: "solid",
  borderColor: "green",
  borderWidth: 5,
};

function ActionUrlButton({ id, data }) {
  const replayheader = data.replayheader;
  const replaybody = data.replaybody;
  const replayfooter = data.replayFooter;
  const replaybuttontext = data.replaybuttontext;
  const [headerText, setHeaderText] = useState(replayheader);
  const [bodyText, setBodyText] = useState(replaybody);
  const [footer, setFooter] = useState(replayfooter);
  const [buttonText, setButtonText] = useState(replaybuttontext);
  const [buttonUrl, setButtonUrl] = useState("https://"); // Default URL value
  const [phoneInput, setPhoneInput] = useState(""); // State for phone input
  const [emailInput, setEmailInput] = useState(""); // State for email input
  const [whatsappInput, setWhatsappInput] = useState(""); // State for WhatsApp input
  const { setNodes } = useReactFlow();
  const [show, setShow] = useState(false);
  const [selectedOption, setSelectedOption] = useState("url"); // Default option
  const [errors, setErrors] = useState({}); // State for validation errors

  // Generate dynamic JSON payload
  const generateMessagePayload = () => {
    const payload = {
      messaging_product: "whatsapp",
      recipient_type: "individual",
      to: "mobile_no", // Use actual phone number or placeholder
      type: "interactive",
      interactive: {
        type: "cta_url",
        header: {
          type: "text",
          text: headerText,
        },
        body: {
          text: bodyText,
        },
        footer: {
          text: footer,
        },
        action: {
          name: "cta_url",
          parameters: {
            display_text: buttonText, // Use button label text
            url: buttonUrl || phoneInput || emailInput || whatsappInput, // Use appropriate URL or input
          },
        },
      },
    };
    return payload;
  };

  // Trigger change whenever relevant state updates
  const handleChange = () => {
    if (data.onChange) {
      const messagePayload = generateMessagePayload();
      data.onChange(id, "actionpayload", messagePayload);
    }
  };

  useEffect(() => {
    handleChange();
  }, [
    headerText,
    bodyText,
    footer,
    buttonText,
    buttonUrl,
    phoneInput,
    emailInput,
    whatsappInput,
  ]);

  // Handle dropdown change
  const handleDropdownChange = (e) => {
    const value = e.target.value;
    setSelectedOption(value);

    // Reset inputs based on the selected option
    setButtonUrl(""); // Clear button URL by default
    setPhoneInput(""); // Clear phone input
    setEmailInput(""); // Clear email input
    setWhatsappInput(""); // Clear WhatsApp input

    if (value === "url") {
      setButtonUrl("https://");
    } else if (value === "phone") {
      setPhoneInput("tel:"); // Clear phone input
    } else if (value === "email") {
      setEmailInput("mailto:"); // Clear email input
    } else if (value === "text") {
      setWhatsappInput("https://wa.me/"); // Clear WhatsApp input
    }
  };

  // Validate fields
  const validateFields = () => {
    const newErrors = {};
    if (!bodyText) {
      newErrors.bodyText = "Body text is required.";
    } else if (bodyText.length > 1024) {
      newErrors.bodyText = "Body text must be 1024 characters or less.";
    }

    if (!buttonText) {
      newErrors.buttonText = "Button label text is required.";
    } else if (buttonText.length > 20) {
      newErrors.buttonText = "Button label text must be 20 characters or less.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Returns true if no errors
  };

  const handleSubmit = () => {
    if (validateFields()) {
      handleChange();
    }
  };

  const handleNodeDelete = () => {
    setNodes((nodes) => nodes.filter((node) => node.id !== id));

    if (data.onChange) {
      data.onChange(id, "actionpayload", null);
    }
  };

  return (
    <div
      className="relative bg-[#ffffff] rounded-[45px] p-7 w-[800px] shadow-2xl group hover:border-solid hover:border-[4px] hover:border-green-600"
      onMouseOver={() => setShow(true)}
      onMouseOut={() => setShow(false)}>
      <Handle
        type="target"
        position={Position.Left}
        id="interactiveCallToActionURLButtonMessages"
        className="ml[10px]"
        style={hfStyle}
      />

      {show && (
        <button
          onClick={handleNodeDelete}
          className="absolute right-2 top-0 text-black text-lg rounded-full p-6 bg-white shadow-2xl hover:text-red-800">
          <RiDeleteBin5Line
            className="text-gray-600 hover:text-red-500"
            style={{ fontSize: 50 }}
          />
        </button>
      )}

      <div className="flex bg-[#def7ec] border-l-[20px] border-green-500 text-green-500 rounded-[20px] mt-1 mb-2 p-4 w-full">
        <FaRegPaperPlane className="mt-5 text-4xl" />{" "}
        <h3 className="text-center text-[40px] mt-2 ml-4">
          Call to Action Message
        </h3>
      </div>

      <div className="bg-[#eae6df] border-[3px] border-solid border-green-600 rounded-[25px] p-3 mt-5">
        <label className="block mt-2 text-3xl font-semibold text-gray-700 mb-3">
          Header Text (Optional)
        </label>
        <input
          type="text"
          placeholder="Enter Header text"
          value={headerText}
          onChange={(e) => setHeaderText(e.target.value)}
          className="text-center rounded-2xl p-10 text-3xl w-full"
        />
        <label className="block mt-11 text-3xl font-semibold text-gray-700 mb-3">
          Body Text (Required)
        </label>
        <textarea
          type="text"
          placeholder="Enter body text
          Maximum 1024 characters"
          value={bodyText}
          onChange={(e) => setBodyText(e.target.value)}
          className={`text-center rounded-2xl p-10 pt-28 text-3xl w-full ${
            errors.bodyText ? "border-red-500" : ""
          }`}
          rows={5}
          cols={50}
        />
        {errors.bodyText && <p className="text-red-500">{errors.bodyText}</p>}

        <label className="block mt-11 text-3xl font-semibold text-gray-700 mb-3">
          Footer Text (Optional)
        </label>
        <input
          type="text"
          placeholder="Enter footer text"
          value={footer}
          onChange={(e) => setFooter(e.target.value)}
          className="text-center rounded-2xl p-10 text-3xl w-full"
        />

        <label className="block mt-11 text-3xl font-semibold text-gray-700 mb-3">
          Button Label Text (Required)
        </label>
        <input
          type="text"
          placeholder="Enter button label text"
          value={buttonText}
          onChange={(e) => setButtonText(e.target.value)}
          className={`text-center rounded-2xl p-10 text-3xl w-full ${
            errors.buttonText ? "border-red-500" : ""
          }`}
        />
        {errors.buttonText && (
          <p className="text-red-500">{errors.buttonText}</p>
        )}

        <select
          className="block w-full text-3xl p-8 rounded-2xl border mt-12 border-gray-300 shadow-sm focus:ring-2 focus:ring-green-600 focus:border-green-600"
          onChange={handleDropdownChange}
          value={selectedOption}>
          <option value="url" className="text-gray-700 text-sm">
            🌐 URL
          </option>
          <option value="text" className="text-gray-700 text-sm">
            💬 WhatsApp
          </option>
          <option value="phone" className="text-gray-700 text-sm">
            📞 Phone
          </option>
          <option value="email" className="text-gray-700 text-sm">
            ✉️ Email
          </option>
        </select>

        {selectedOption === "url" && (
          <>
            <label className="block mt-11 text-3xl font-semibold text-gray-700 mb-3">
              Button URL
            </label>
            <input
              type="url"
              placeholder="Enter button URL"
              value={buttonUrl}
              onChange={(e) => setButtonUrl(e.target.value)}
              className="text-center rounded-2xl p-10 text-3xl w-full"
            />
          </>
        )}

        {selectedOption === "phone" && (
          <>
            <label className="block mt-11 text-3xl font-semibold text-gray-700 mb-3">
              Phone Number
            </label>
            <input
              type="tel"
              placeholder="Enter phone number"
              value={phoneInput}
              onChange={(e) => setPhoneInput(e.target.value)}
              className="text-center rounded-2xl p-10 text-3xl w-full"
            />
          </>
        )}

        {selectedOption === "email" && (
          <>
            <label className="block mt-11 text-3xl font-semibold text-gray-700 mb-3">
              Email Address
            </label>
            <input
              type="email"
              placeholder="Enter email address"
              value={emailInput}
              onChange={(e) => setEmailInput(e.target.value)}
              className="text-center rounded-2xl p-10 text-3xl w-full"
            />
          </>
        )}

        {selectedOption === "text" && (
          <>
            <label className="block mt-11 text-3xl font-semibold text-gray-700 mb-3">
              WhatsApp Number
            </label>
            <input
              type="tel"
              placeholder="Enter WhatsApp number"
              value={whatsappInput}
              onChange={(e) => setWhatsappInput(e.target.value)}
              className="text-center rounded-2xl p-10 text-3xl w-full"
            />
          </>
        )}
      </div>
    </div>
  );
}

export default ActionUrlButton;
