import React, { useState, useEffect } from "react";
import { Handle, Position, useReactFlow } from "reactflow";
import { FaBoxOpen } from "react-icons/fa";
import { RiDeleteBin5Line } from "react-icons/ri";
import { message } from "antd";
import "reactflow/dist/style.css";

const hfStyle = {
  backgroundColor: "white",
  top: 8,
  padding: 12,
  borderStyle: "solid",
  borderColor: "green",
  borderWidth: 5,
};

const hfStylebutton = {
  backgroundColor: "transparent", // Ensures a truly transparent background
  top: 145,
  right: -70,
  padding: 8,
  borderStyle: "solid",
  borderColor: "#008000", // Light green color for the border (adjust as needed)
  borderWidth: 4, // Adjust the border width to your preference
  width: 100,
  height: 10,
  borderRadius: 10,
};

function MultiProductListNode({ id, data }) {
  const phoneNumberId = localStorage.getItem("phId") || "";
  const [headerText, setHeaderText] = useState("");
  const [bodyText, setBodyText] = useState("");
  const [footerText, setFooterText] = useState("");
  const [catalogId, setCatalogId] = useState(""); // Hidden catalog ID field
  const [sections, setSections] = useState([
    {
      title: "",
      product_items: [{ product_retailer_id: "" }],
    },
  ]);
  const [productOptions, setProductOptions] = useState([]); // Product options for dropdown
  const { setNodes } = useReactFlow();
  const [show, setShow] = useState(false);

  // Fetch product options and set the catalog ID
  const fetchProductOptions = async () => {
    try {
      const response = await fetch(
        "https://app.botlytix.com/getProductsFromCommerce",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            phone_number_id: phoneNumberId, // Ensure correct format
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      console.log("Product data fetched successfully:", data);
      setProductOptions(data.products); // Assuming the response contains a 'products' array
      setCatalogId(data.catalogue_id); // Automatically set the catalog ID
    } catch (error) {
      console.error("Error fetching product options", error);
    }
  };

  useEffect(() => {
    fetchProductOptions();
  }, []);

  // Generate dynamic JSON payload based on the input
  const generateMessagePayload = () => {
    const payload = {
      messaging_product: "whatsapp",
      recipient_type: "individual",
      to: "PHONE_NUMBER", // Replace with actual recipient phone number
      type: "interactive",
      interactive: {
        type: "product_list",
        header: {
          type: "text",
          text: headerText,
        },
        body: {
          text: bodyText,
        },
        footer: {
          text: footerText,
        },
        action: {
          catalog_id: catalogId, // Hidden catalog ID, used in the payload
          sections: sections.map((section) => ({
            title: section.title,
            product_items: section.product_items.map((item) => ({
              product_retailer_id: item.product_retailer_id,
            })),
          })),
        },
      },
    };
    return payload;
  };

  // Trigger change whenever relevant state updates
  const handleChange = () => {
    if (data.onChange) {
      const messagePayload = generateMessagePayload();
      data.onChange(id, "MultiProductListNodepayload", messagePayload);
    }
  };

  useEffect(() => {
    handleChange();
  }, [headerText, bodyText, footerText, catalogId, sections]);

  const handleAddSection = () => {
    if (sections.length < 10) {
      setSections((prevSections) => [
        ...prevSections,
        { title: "", product_items: [{ product_retailer_id: "" }] },
      ]);
    }
  };

  const handleAddProduct = (sectionIndex) => {
    const updatedSections = [...sections];
    const lastProduct = updatedSections[sectionIndex].product_items.at(-1);

    if (lastProduct && !lastProduct.product_retailer_id) {
      message.warning("Please select a product ID before adding a new one.");
      return;
    }

    if (updatedSections[sectionIndex].product_items.length < 3) {
      updatedSections[sectionIndex].product_items.push({
        product_retailer_id: "",
      });
      setSections(updatedSections);
    } else {
      message.error("Maximum 3 products allowed per section");
    }
  };

  const handleDeleteProduct = (sectionIndex, productIndex) => {
    const updatedSections = [...sections];
    updatedSections[sectionIndex].product_items.splice(productIndex, 1);
    setSections(updatedSections);
  };

  const handleDeleteSection = (sectionIndex) => {
    if (sectionIndex > 0) {
      const updatedSections = sections.filter(
        (_, index) => index !== sectionIndex
      );
      setSections(updatedSections);
    }
  };

  const handleNodeDelete = () => {
    setNodes((prevNodes) => prevNodes.filter((node) => node.id !== id));
    if (data.onChange) {
      data.onChange(id, "MultiProductListNodepayload", null); // Notify parent of text removal
    }
  };

  return (
    <div
      className="relative bg-[#ffffff] rounded-[45px] p-7 w-[800px] shadow-2xl group hover:border-solid hover:border-[4px] hover:border-green-600"
      onMouseOver={() => setShow(true)}
      onMouseOut={() => setShow(false)}>
      <Handle
        type="target"
        position={Position.Left}
        id="multiProductMessage"
        style={hfStyle}
      />

      {show && (
        <button
          onClick={handleNodeDelete}
          className="absolute right-2 top-0 text-black text-lg rounded-full p-6 bg-white shadow-2xl hover:text-red-800">
          <RiDeleteBin5Line
            className="text-gray-600 hover:text-red-500"
            style={{ fontSize: 50 }}
          />
        </button>
      )}

      <div className="flex bg-[#def7ec] border-l-[20px] border-green-500 text-green-500 rounded-[20px] mt-1 mb-2 p-4 w-full">
        <FaBoxOpen className="mt-5 text-4xl" />
        <h3 className="text-center text-[40px] mt-2 ml-4">
          Product List Message
        </h3>
      </div>

      <div className="bg-[#eae6df] border-[3px] border-solid border-green-600 rounded-[25px] p-3 mt-5">
        <label className="block p-4 text-3xl font-semibold text-gray-700 mb-3">
          Header Text
        </label>
        <input
          value={headerText}
          onChange={(e) => setHeaderText(e.target.value)}
          placeholder="Enter header text"
          className="text-center rounded-2xl p-10 text-3xl w-full"
        />

        <label className="block mt-11 text-3xl font-semibold text-gray-700 mb-3">
          Body Text (Required)
        </label>
        <textarea
          value={bodyText}
          onChange={(e) => setBodyText(e.target.value)}
          placeholder="Enter body text"
          rows={4}
          cols={50}
          className="text-center rounded-2xl p-10 text-3xl w-full"
        />

        <label className="block mt-11 text-3xl font-semibold text-gray-700 mb-3">
          Footer Text (Optional)
        </label>
        <input
          value={footerText}
          onChange={(e) => setFooterText(e.target.value)}
          placeholder="Enter footer text"
          className="text-center rounded-2xl p-10 text-3xl w-full"
        />

        {/* Catalog ID is hidden but used in the payload */}
        <input type="hidden" value={catalogId} />

        {sections.map((section, sectionIndex) => (
          <div key={sectionIndex} className="mt-4">
            <label className="block mt-11 mb-2 text-3xl font-semibold text-gray-700">
              Section {sectionIndex + 1} Title (Required)
            </label>
            <input
              value={section.title}
              onChange={(e) => {
                const updatedSections = [...sections];
                updatedSections[sectionIndex].title = e.target.value;
                setSections(updatedSections);
              }}
              placeholder="Section Title"
              className="text-center rounded-2xl p-10 text-3xl w-full"
            />

            {section.product_items.map((product, productIndex) => (
              <div key={productIndex} className="relative mt-4">
                <label className="block mt-11 mb-2 text-3xl font-semibold text-gray-700">
                  Product {productIndex + 1} (Required)
                </label>
                <select
                  value={product.product_retailer_id}
                  onChange={(e) => {
                    const updatedSections = [...sections];
                    updatedSections[sectionIndex].product_items[
                      productIndex
                    ].product_retailer_id = e.target.value;
                    setSections(updatedSections);
                  }}
                  className="text-center rounded-2xl p-10 text-3xl w-full">
                  <option value="" className="text-gray-700 text-[10px] pt-5">
                    Select Product
                  </option>
                  {productOptions.map((option) => (
                    <option
                      key={option.retailer_id}
                      value={option.retailer_id}
                      className="text-gray-700 text-[10px] pt-5">
                      {option.product_name}
                    </option>
                  ))}
                </select>

                {productIndex > 0 && (
                  <button
                    onClick={() =>
                      handleDeleteProduct(sectionIndex, productIndex)
                    }
                    className="absolute top-0 right-0 text-red-500">
                    <RiDeleteBin5Line style={{ fontSize: 30 }} />
                  </button>
                )}
              </div>
            ))}

            <div className="mt-4 flex justify-between">
              <button
                onClick={() => handleAddProduct(sectionIndex)}
                className="bg-green-500 text-white text-3xl rounded-xl p-7">
                + Add Product
              </button>
              {sectionIndex > 0 && (
                <button
                  onClick={() => handleDeleteSection(sectionIndex)}
                  className="bg-red-500 text-white text-3xl rounded-xl p-7">
                  Delete Section
                </button>
              )}
            </div>
          </div>
        ))}

        <button
          onClick={handleAddSection}
          className="bg-blue-500 text-white text-3xl rounded-xl p-7 mt-5 w-full">
          + Add Section
        </button>
      </div>

      {/* <Handle
        type="source"
        position={Position.Left}
        id={"product_list_node_out"}
        style={hfStylebutton}
      /> */}
    </div>
  );
}

export default MultiProductListNode;
