import React from "react";
import Slider from "react-slick"; // Import Slider
import { FaTimes } from "react-icons/fa"; // Close Icon

import catalog from "../../../assests/catalog.png";
import catalog2 from "../../../assests/catalog2.png";
import sProduct from "../../../assests/singleProduct.png";
import sProduct2 from "../../../assests/singleProduct2.png";
import mproduct from "../../../assests/multiProduct.png";
import mproduct2 from "../../../assests/multiProduct2.png";

const PrevArrow = (props) => {
  const { onClick } = props;
  return (
    <div
      className="absolute left-1 top-1/2 transform -translate-y-1/2 cursor-pointer bg-gray-100 w-8 h-8 rounded-full flex items-center justify-center transition-colors duration-300 hover:bg-green-400"
      onClick={onClick}
      style={{ zIndex: 1 }}>
      <span className="text-black text-2xl hover:text-white">&lt;</span>
    </div>
  );
};

const NextArrow = (props) => {
  const { onClick } = props;
  return (
    <div
      className="absolute right-1 top-1/2 transform -translate-y-1/2 cursor-pointer bg-gray-100 w-8 h-8 rounded-full flex items-center justify-center transition-colors duration-300 hover:bg-green-400"
      onClick={onClick}
      style={{ zIndex: 1 }}>
      <span className="text-black text-2xl hover:text-white">&gt;</span>
    </div>
  );
};

const PopupCarouselModal = ({ isOpen, onRequestClose }) => {
  if (!isOpen) return null;

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  const title = "Product Types";
  const messageCatalog =
    "Catalog messages are messages that allow you to showcase your product catalog entirely within WhatsApp.Catalog messages display a product thumbnail header image of your choice, custom body text, a fixed text header, a fixed text sub-header, and a View catalog button.";

  const messageSingle =
    "Single-Product Message (SPM) template messages present a single product from your ecommerce catalog, accompanied by a product image, product title, and product price (all pulled from your product within your catalog), along with customizable body text, optional footer text, and an interactive View button.";
  const messageMulti =
    "Multi-Product Message (MPM) template messages present product information for up to 30 products from your ecommerce catalog, organized customizable by sections.";
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
      <div className="bg-white rounded-lg shadow-lg p-6 w-[700px] h-[450px] relative">
        <button
          className="absolute top-3 right-3 text-gray-700"
          onClick={onRequestClose}>
          <FaTimes className="text-xl hover:text-red-500" />
        </button>

        {/* Carousel */}
        <Slider {...settings}>
          <div className="carousel-item">
            <h2 className="text-xl font-semibold mb-4 text-center">
              Catalog Message (Demo Look)
            </h2>
            <div className="flex justify-between gap-4 mb-4 pr-32 pl-32">
              <div>
                <img
                  src={catalog}
                  alt="Text Demo Image"
                  className="w-[200px]   object-cover rounded-lg"
                />
              </div>

              <img
                src={catalog2}
                alt="Text Demo Image"
                className="w-[150px] object-cover rounded-lg"
              />
            </div>
            <p className="text-center text-sm mb-4">{messageCatalog}</p>
          </div>
          <div className="carousel-item">
            <h2 className="text-xl font-semibold mb-4 text-center">
              SingleProduct Message (Demo Look)
            </h2>
            <div className="flex justify-between gap-4 mb-4 pr-32 pl-32">
              <div>
                <img
                  src={sProduct}
                  alt="Text Demo Image"
                  className="w-[200px]   object-cover rounded-lg"
                />
              </div>

              <img
                src={sProduct2}
                alt="Text Demo Image"
                className="w-[150px] object-cover rounded-lg"
              />
            </div>
            <p className="text-center text-sm mb-4">{messageSingle}</p>
          </div>
          <div className="carousel-item">
            <h2 className="text-xl font-semibold mb-4 text-center">
              MultiProduct Message (Demo Look)
            </h2>
            <div className="flex justify-between gap-4 mb-4 pr-32 pl-32">
              <div>
                <img
                  src={mproduct}
                  alt="Text Demo Image"
                  className="w-[200px]   object-cover rounded-lg"
                />
              </div>

              <img
                src={mproduct2}
                alt="Text Demo Image"
                className="w-[150px] object-cover rounded-lg"
              />
            </div>
            <p className="text-center text-sm mb-4">{messageMulti}</p>
          </div>
        </Slider>
      </div>
    </div>
  );
};

export default PopupCarouselModal;
