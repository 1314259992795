import React from "react";
import Slider from "react-slick"; // Import Slider
import { FaTimes } from "react-icons/fa"; // Close Icon
import PaymentGateway from "../../../assests/payment.gif";
import PaymentLink from "../../../assests/paymentLink.png";

const PrevArrow = (props) => {
  const { onClick } = props;
  return (
    <div
      className="absolute left-1 top-1/2 transform -translate-y-1/2 cursor-pointer bg-gray-100 w-8 h-8 rounded-full flex items-center justify-center transition-colors duration-300 hover:bg-green-400"
      onClick={onClick}
      style={{ zIndex: 1 }}>
      <span className="text-black text-2xl hover:text-white">&lt;</span>
    </div>
  );
};

const NextArrow = (props) => {
  const { onClick } = props;
  return (
    <div
      className="absolute right-1 top-1/2 transform -translate-y-1/2 cursor-pointer bg-gray-100 w-8 h-8 rounded-full flex items-center justify-center transition-colors duration-300 hover:bg-green-400"
      onClick={onClick}
      style={{ zIndex: 1 }}>
      <span className="text-black text-2xl hover:text-white">&gt;</span>
    </div>
  );
};

const PopupPaymentCarousel = ({ isOpen, onRequestClose }) => {
  if (!isOpen) return null;

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  const title = "Payment Type";
  const message = "Carousel Message";

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
      <div className="bg-white rounded-lg shadow-lg p-6 w-[700px] h-[550px] relative">
        <button
          className="absolute top-3 right-3 text-gray-700"
          onClick={onRequestClose}>
          <FaTimes className="text-xl hover:text-red-500" />
        </button>

        {/* Carousel */}
        <Slider {...settings}>
          <div className="carousel-item flex justify-center">
            <h2 className="text-xl font-semibold mb-10 text-center">
              Payment Gateway (Demo Look)
            </h2>
            <img
              src={PaymentGateway}
              alt="Carousel Item 1"
              className="w-[700px] ml-2 h-[300px] object-contain"
            />
            <p className="text-center  mt-10">
              Your business can enable customers to pay for their orders through
              our partner payment gateways without leaving WhatsApp. Businesses
              can send customers order_details messages, then get notified about
              payment status updates via webhook notifications.
            </p>
          </div>
          <div className="carousel-item flex justify-center">
            <h2 className="text-xl font-semibold mb-10 text-center">
              Payment Link (Demo Look)
            </h2>
            <img
              src={PaymentLink}
              alt="Carousel Item 2"
              className="w-[700px] h-[300px]  object-contain"
            />
            <p className="text-center  mt-10">
              Your businesses can enable customers to pay for their orders by
              bringing in all the payment methods supported on your platform to
              WhatsApp. Businesses can send customers invoice(order_details)
              messages, then get notified about payment status updates via
              webhook notifications from Payment Gateway.
            </p>
          </div>
        </Slider>
      </div>
    </div>
  );
};

export default PopupPaymentCarousel;
