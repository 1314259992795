import React from "react";
import Modal from "react-modal";

const PopupModalForm = ({
  isOpen,
  onRequestClose,
  title,
  message,
  image,
  who,
}) => {
  return (
    <>
      {who === "Form" && (
        <Modal
          isOpen={isOpen}
          onRequestClose={onRequestClose}
          ariaHideApp={false}
          className="flex items-center justify-center transition-transform transform duration-300 ease-in-out w-full"
          overlayClassName="fixed inset-0 bg-white bg-opacity-30 flex items-center justify-center">
          <div className="bg-white p-6 rounded-xl shadow-lg w-[700px] h-[550px] relative">
            {/* Close Icon in the Top Right */}
            <button
              onClick={onRequestClose}
              className="absolute top-3 right-3 text-gray-600 hover:text-gray-900 transition duration-200 ease-in-out">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="2">
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>

            {/* Title */}
            <h2 className="text-2xl font-bold text-center mb-4">{title}</h2>

            {/* Image */}
            <img
              src={image}
              alt="Text Demo Image"
              className="w-[300px] ml-44 mt-10 object-cover rounded-lg mb-4"
            />

            {/* Message */}
            <p className="mt-10 text-center">{message}</p>
          </div>
        </Modal>
      )}
    </>
  );
};

export default PopupModalForm;
