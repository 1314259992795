import React, { useState, useEffect } from "react";
import { Handle, Position, useReactFlow } from "reactflow";
import { RiDeleteBin5Line } from "react-icons/ri";
import { FaGoogle } from "react-icons/fa";

function GoogleFormComponent({ id, data }) {
  const googleFormStyle = {
    backgroundColor: "white",
    top: 20,
    padding: 12,
    borderStyle: "solid",
    borderColor: "green",
    borderWidth: 5,
  };
  const { setNodes } = useReactFlow();
  const [ceatName, setCeatName] = useState("");
  const [ceatId, setCeatId] = useState("");
  const [show, setShow] = useState(false);

  // Function to generate the JSON payload
  const generateGoogleFormPayload = () => {
    return {
      ceatName: ceatName,
      ceatId: ceatId,
    };
  };

  // Effect to notify parent about input changes
  useEffect(() => {
    if (data.onChange) {
      const googleFormPayload = generateGoogleFormPayload();
      data.onChange(id, "googleFormPayload", googleFormPayload);
    }
  }, [ceatName, ceatId, id, data]);

  const handleDeleteNode = () => {
    setNodes((prevNodes) => prevNodes.filter((node) => node.id !== id));
    if (data.onChange) {
      data.onChange(id, "googleFormPayload", null); // Notify parent of removal
    }
  };

  return (
    <div
      className="bg-[#ffffff] rounded-[20px] p-7 shadow-2xl hover:border-solid hover:border-[4px] hover:border-green-500"
      onMouseOver={() => setShow(true)}
      onMouseOut={() => setShow(false)}>
      <Handle
        type="target"
        position={Position.Left}
        id="googleform"
        className="ml[10px]"
        style={googleFormStyle}
      />

      <div className="flex bg-[#def7ec] border-l-[20px] border-green-500 text-green-500 rounded-[20px] mt-1 mb-2 p-4 ">
        <FaGoogle className="mt-5 text-4xl" />
        <h3 className="text-center text-[40px] mt-2 ml-4">Google Form</h3>
      </div>

      {/* Wrapper div for both input fields */}
      <div className="bg-[#eae6df] border-[3px] border-solid border-green-600 rounded-[25px] p-3 mt-5">
        <div>
          <input
            type="text"
            placeholder="Enter ceat id"
            className="text-center rounded-2xl p-2 mt-3 text-3xl h-[80px] w-[500px] mb-4"
            value={ceatId}
            onChange={(e) => setCeatId(e.target.value)}
          />
        </div>

        <input
          type="text"
          placeholder="Enter ceat name"
          className="text-center rounded-2xl p-2 mt-3 text-3xl h-[80px] w-[500px] mb-4"
          value={ceatName}
          onChange={(e) => setCeatName(e.target.value)}
        />
      </div>

      <div className="absolute right-0 top-0">
        <button
          onClick={handleDeleteNode}
          className="text-black text-lg rounded-full p-6 bg-white shadow-2xl hover:text-red-800">
          <RiDeleteBin5Line
            className="text-gray-600 hover:text-red-500"
            style={{ fontSize: 50 }}
          />
        </button>
      </div>
    </div>
  );
}

export default GoogleFormComponent;
