import React, { useState, useEffect, useRef } from "react";
import { Handle, Position, useReactFlow } from "reactflow";
import { RiDeleteBin5Line } from "react-icons/ri";
import { FaMobileScreenButton } from "react-icons/fa6";
import { message } from "antd";
import "reactflow/dist/style.css";
import { uploadMedia } from "../api/MediaID";

const hfStyle = {
  backgroundColor: "white",
  top: 8,
  padding: 12,
  borderStyle: "solid",
  borderColor: "green",
  borderWidth: 5,
};

function PaymentOrderDetailsNode({ id, data }) {
  const [orderDetails, setOrderDetails] = useState({
    mediaId: null,
    text: "",
    footerText: "",
    totalAmount: 21000, // Example default amount
    currency: "INR",
    referenceId: "",
    paymentLink: "",
    expirationTimestamp: "",
    items: [],
    catalogId: "",
  });
  const { setNodes } = useReactFlow();
  const fileInputRef = useRef(null);
  const [file, setFile] = useState(null);
  const [show, setShow] = useState(false);

  const handleFileChange = async (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      try {
        const mediaId = await uploadMedia(selectedFile);
        setOrderDetails((prevDetails) => ({ ...prevDetails, mediaId }));
        message.success("Media uploaded successfully");
      } catch (error) {
        message.error(error.message);
      }
    }
  };

  const handleInputChange = (field, value) => {
    setOrderDetails((prevDetails) => ({ ...prevDetails, [field]: value }));
  };

  const handleDeleteNode = () => {
    setNodes((prevNodes) => prevNodes.filter((node) => node.id !== id));
    if (data.onChange) {
      data.onChange(id, "PaymentOrderDetailsNode", null);
    }
  };

  const shows = () => setShow(true);
  const leave = () => setShow(false);

  const generateOrderDetailsJSON = () => {
    const {
      mediaId,
      text,
      footerText,
      totalAmount,
      currency,
      referenceId,
      paymentLink,
      expirationTimestamp,
      items,
      catalogId,
    } = orderDetails;

    return {
      interactive: {
        type: "order_details",
        header: {
          type: "image",
          image: {
            id: mediaId,
            provider: {
              name: "provider-name",
            },
          },
        },
        body: {
          text,
        },
        footer: {
          text: footerText,
        },
        action: {
          name: "review_and_pay",
          parameters: {
            reference_id: referenceId,
            type: "digital-goods",
            payment_type: "upi",
            payment_settings: [
              {
                type: "payment_link",
                payment_link: {
                  uri: paymentLink,
                },
              },
            ],
            currency,
            total_amount: {
              value: totalAmount,
              offset: 100,
            },
            order: {
              status: "pending",
              catalog_id: catalogId,
              expiration: {
                timestamp: expirationTimestamp,
                description: "cancellation-explanation",
              },
              items,
            },
          },
        },
      },
    };
  };

  useEffect(() => {
    if (data.onChange) {
      const payload = generateOrderDetailsJSON();
      data.onChange(id, "PaymentOrderDetailsNode", payload);
    }
  }, [orderDetails, id, data]);

  return (
    <div
      className="relative bg-[#ffffff] rounded-[45px] p-7 w-[800px] shadow-2xl group hover:border-solid hover:border-[4px] hover:border-green-600"
      onMouseOver={shows}
      onMouseOut={leave}>
      <Handle
        type="target"
        position={Position.Left}
        id="order_details_node"
        style={hfStyle}
      />

      {show && (
        <button
          onClick={handleDeleteNode}
          className="absolute right-2 top-0 text-black text-lg rounded-full p-6 bg-white shadow-2xl hover:text-red-800">
          <RiDeleteBin5Line
            className="text-gray-600 hover:text-red-500"
            style={{ fontSize: 50 }}
          />
        </button>
      )}

      <div className="flex bg-[#def7ec] border-l-[20px] border-green-500 text-green-500 rounded-[20px] mt-1 mb-2 p-4 w-full">
        <FaMobileScreenButton className="mt-5 text-4xl" />
        <h3 className="text-center text-[40px] mt-2 ml-4">Order Details</h3>
      </div>

      <div className="bg-[#eae6df] border-[3px] border-solid border-green-600 rounded-[25px] p-3 mt-5">
        <label className="block p-4 text-3xl font-semibold text-gray-700 mb-3">
          Order Text
        </label>
        <textarea
          value={orderDetails.text}
          onChange={(e) => handleInputChange("text", e.target.value)}
          className="block w-full text-3xl p-9 rounded-lg border border-gray-300 shadow-sm focus:ring-2 focus:ring-green-600 focus:border-green-600"
          placeholder="Enter order text"
        />

        <label className="block p-4 text-3xl font-semibold text-gray-700 mt-4 mb-3">
          Footer Text
        </label>
        <input
          type="text"
          value={orderDetails.footerText}
          onChange={(e) => handleInputChange("footerText", e.target.value)}
          className="block w-full text-3xl p-4 rounded-lg border border-gray-300 shadow-sm focus:ring-2 focus:ring-green-600 focus:border-green-600"
          placeholder="Enter footer text"
        />

        <div className="mt-4">
          <input
            type="file"
            ref={fileInputRef}
            onChange={handleFileChange}
            accept=".jpeg,.jpg,.png"
            style={{ display: "none" }}
          />
          {file ? (
            <img
              src={URL.createObjectURL(file)}
              alt="Uploaded"
              className="rounded-2xl mx-auto"
              style={{ maxWidth: "100%", maxHeight: "100%" }}
            />
          ) : (
            <button
              onClick={() => fileInputRef.current.click()}
              className="flex flex-col items-center justify-center p-4 border w-full h-[350px] border-gray-300 rounded-lg shadow-lg bg-white">
              Upload an Image
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default PaymentOrderDetailsNode;
