import React, { useState, useEffect } from "react";
import { Handle, Position, useReactFlow } from "reactflow";
import { RiDeleteBin5Line } from "react-icons/ri";
import { FaFacebookMessenger } from "react-icons/fa";

const textAreaStyle = {
  backgroundColor: "white",
  top: 20,
  padding: 12,
  borderStyle: "solid",
  borderColor: "green",
  borderWidth: 5,
};

function TextAreaComponent({ id, data }) {
  const bodies = data.bodytext;
  const { setNodes } = useReactFlow();
  const [text, setText] = useState(bodies);
  const [show, setShow] = useState(false);
  const [error, setError] = useState(""); // State to hold error messages

  // Generate the WhatsApp message payload
  const generateMessagePayload = () => {
    const payload = {
      messaging_product: "whatsapp",
      recipient_type: "individual",
      to: "mobile_no", // Replace with actual recipient number
      type: "text",
      text: {
        preview_url: true,
        body: text,
      },
    };
    return payload;
  };

  // Effect to notify parent about text changes and update payload
  useEffect(() => {
    if (data.onChange) {
      const messagePayload = generateMessagePayload();
      data.onChange(id, "textMessagePayload", messagePayload, setText); // Send the updated payload
    }
  }, [text, id, data]);

  const handleInputChange = (e) => {
    const newText = e.target.value;

    // Validate character limit
    if (newText.length > 4096) {
      setError("Maximum character limit is 4096.");
      return; // Prevent setting the text if limit exceeded
    }

    // Check if the text contains valid URLs (optional)
    const urlRegex = /(https?:\/\/[^\s]+)/g; // Basic URL regex
    if (newText && !urlRegex.test(newText) && newText.includes("http")) {
      setError("Please enter valid URLs.");
      return;
    } else {
      setError(""); // Clear error if input is valid
    }

    setText(newText);
  };

  const shows = () => setShow(true);
  const leave = () => setShow(false);
  const handleDeleteNode = () => {
    setNodes((prevNodes) => prevNodes.filter((node) => node.id !== id));
    if (data.onChange) {
      data.onChange(id, "textMessagePayload", null); // Notify parent of text removal
    }
  };

  return (
    <div
      className="bg-[#ffffff] rounded-[20px] p-7 shadow-2xl hover:border-solid hover:border-[4px] hover:border-green-600"
      onMouseOver={shows}
      onMouseOut={leave}>
      <Handle
        type="target"
        position={Position.Left}
        id="text"
        className="ml[10px]"
        style={textAreaStyle}
      />

      {show && (
        <div className="">
          <button
            onClick={handleDeleteNode}
            className="absolute right-0 top-0 text-black text-lg rounded-full p-6 bg-white shadow-2xl hover:text-red-800">
            <RiDeleteBin5Line
              className="text-gray-600 hover:text-red-500"
              style={{ fontSize: 50 }}
            />
          </button>
        </div>
      )}

      <div className="flex bg-[#def7ec] border-l-[20px] border-green-500 text-green-500 rounded-[20px] mt-1 mb-2 p-4 w-full">
        <FaFacebookMessenger className="mt-5 text-4xl" />
        <h3 className="text-center text-[40px] mt-2 ml-4">Text Message</h3>
      </div>

      <div className="bg-[#eae6df] border-[3px] border-solid border-green-600 rounded-[25px] p-3 mt-5">
        <textarea
          placeholder="Enter your text here 
          (Maximum 4096 characters)"
          className="text-center rounded-2xl p-2 mt-3 pt-14 text-3xl h-[150px] w-[500px] mb-4"
          value={text}
          onChange={handleInputChange}
        />
        {/* Display error message if there is one */}
        {error && <p className="text-red-500 text-sm">{error}</p>}
      </div>
    </div>
  );
}

export default TextAreaComponent;
