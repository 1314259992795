export const uploadMedia = async (file, type) => {
  const phoneNumberId = localStorage.getItem("phId") || "";
  const myHeaders = new Headers();
  myHeaders.append(
    "Authorization",
    "Bearer EAASZCnjWBPK8BO0rscXbhsN3ZCGkvZBZAeS60t9LHttXdncUrmWtlNZAhBUmc07B7JRDZAAOAEbMPwD8egjn1sQvOl1Xy0ZCSrXQ4ZASbILyZB9dFGGQqS2VZCZCb2y8nlZC6C5nEDEPRVg8ZAxkHpZCsn6ZANmoTMEBRgeJmmkOJoZBmiyFiMk2O5pRDHbgad82F9kUyZCsNvvuq0NZCZAbcjqZBYpw" // Replace with your actual token
  );

  const formdata = new FormData();
  formdata.append("file", file);
  formdata.append("type", file.type);
  formdata.append("messaging_product", "whatsapp");

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `https://graph.facebook.com/v21.0/${phoneNumberId}/media`,
      requestOptions
    );
    const result = await response.json();

    if (response.ok) {
      return result.id; // Return media ID on success
    } else {
      throw new Error("Upload failed");
    }
  } catch (error) {
    throw new Error("Error uploading media");
  }
};
