import React, { useState, useEffect } from "react";
import { Handle, Position, useReactFlow } from "reactflow";
import { RiDeleteBin5Line } from "react-icons/ri";

import { IoTime } from "react-icons/io5";

function TimeDelayComponent({ id, data }) {
  const timeDelayStyle = {
    backgroundColor: "white",
    top: 20,
    padding: 12,
    borderStyle: "solid",
    borderColor: "green",
    borderWidth: 5,
  };
  const hfStylebutton = {
    backgroundColor: "transparent", // Ensures a truly transparent background
    top: 218,
    right: -40,
    padding: 8,
    borderStyle: "solid",
    borderColor: "#008000", // Light green color for the border (adjust as needed)
    borderWidth: 4, // Adjust the border width to your preference
    width: 100,
    height: 10,
    borderRadius: 10,
  };
  const { setNodes } = useReactFlow();
  const [minutes, setMinutes] = useState(""); // Input for minutes
  const [show, setShow] = useState(false);

  // Function to generate the JSON payload with Unix timestamp in seconds
  const generateTimeDelayPayload = () => {
    return {
      Timedelay: minutes ? parseInt(minutes, 10) * 60 : null, // Convert minutes to seconds
    };
  };

  // Effect to notify parent about time changes
  useEffect(() => {
    if (data.onChange) {
      const timeDelayPayload = generateTimeDelayPayload();
      data.onChange(id, "timeDelayPayload", timeDelayPayload);
    }
  }, [minutes, id, data]);

  const handleDeleteNode = () => {
    setNodes((prevNodes) => prevNodes.filter((node) => node.id !== id));
    if (data.onChange) {
      data.onChange(id, "timeDelayPayload", null); // Notify parent of removal
    }
  };

  // Handle input change for minutes
  const handleMinutesChange = (event) => {
    const value = event.target.value;
    // Ensure that the input is a valid number and non-negative
    if (value === "" || (!isNaN(value) && Number(value) >= 0)) {
      setMinutes(value);
    }
  };

  const shows = () => setShow(true);
  const leave = () => setShow(false);
  return (
    <div
      className="bg-[#ffffff] rounded-[20px] p-7 shadow-2xl hover:border-solid hover:border-[4px] hover:border-green-600"
      onMouseOver={shows}
      onMouseOut={leave}>
      <Handle
        type="target"
        position={Position.Left}
        id="timedelay"
        className="ml[10px]"
        style={timeDelayStyle}
      />

      {show && (
        <div className="">
          <button
            onClick={handleDeleteNode}
            className="absolute right-0 top-0 text-black text-lg rounded-full p-6 bg-white shadow-2xl hover:text-red-800">
            <RiDeleteBin5Line
              className="text-gray-600 hover:text-red-500"
              style={{ fontSize: 50 }}
            />
          </button>
        </div>
      )}

      <div className="flex bg-[#def7ec] border-l-[20px] border-green-500 text-green-500 rounded-[20px] mt-1 mb-2 p-4 w-full">
        <IoTime className="mt-5 text-4xl" />
        <h3 className="text-center text-[40px] mt-2 ml-4">Time Delay</h3>
      </div>

      <div className="bg-[#eae6df] border-[3px] border-solid border-green-600 rounded-[25px] p-3 mt-5">
        <input
          type="text" // Enforces numeric input
          min="0" // Prevents negative values
          placeholder="Enter Minutes"
          className="text-center rounded-2xl p-2 mt-3 text-3xl h-[80px] w-[500px] mb-4"
          value={minutes}
          onChange={handleMinutesChange}
        />
      </div>
      <Handle
        type="source"
        position={Position.Left}
        id={"Time_delay_id"}
        style={hfStylebutton}
      />
    </div>
  );
}

export default TimeDelayComponent;
