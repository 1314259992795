import React from "react";
import { FaMobileScreenButton, FaTimes } from "react-icons/fa"; // Import close icon
import { FaFaceAngry } from "react-icons/fa6";
import { FaBook } from "react-icons/fa";
import { MdLocalOffer } from "react-icons/md";
import { FaBoxOpen } from "react-icons/fa";

function PopupProductModal({ isOpen, onRequestClose, onNodeSelect }) {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
      <div className="bg-white rounded-lg shadow-lg p-6 w-[500px] h-[250px] relative">
        {" "}
        {/* Added relative positioning */}
        <button
          className="absolute top-3 right-3 text-gray-700" // Positioning the close button
          onClick={onRequestClose}>
          <FaTimes className="text-xl" />
        </button>
        <h2 className="text-xl font-semibold mb-4 text-center">
          Select Product Type
        </h2>
        <div className="flex flex-row mt-10 justify-evenly">
          {" "}
          {/* Use justify-around for evenly spaced buttons */}
          {/* Catalog Node Button */}
          <div className="flex flex-col ms-3 items-center">
            <button
              className="bg-gray-200 rounded-full p-4 flex  items-center justify-center w-20 h-20"
              onClick={() => onNodeSelect("catalogNode")}>
              <FaBook className="text-green-700 text-2xl" />
            </button>
            <span className="mt-2 text-gray-700">Catalog</span>
          </div>
          {/* Single Product Node Button */}
          <div className="flex flex-col ms-4 items-center">
            <button
              className="bg-gray-200 rounded-full p-4 flex items-center justify-center w-20 h-20"
              onClick={() => onNodeSelect("SingleProductNode")}>
              <MdLocalOffer className="text-green-700 text-2xl" />
            </button>
            <span className="mt-2  text-gray-700">Single Product</span>
          </div>
          {/* Multi Product List Node Button */}
          <div className="flex flex-col items-center">
            <button
              className="bg-gray-200 rounded-full p-4 flex items-center justify-center w-20 h-20"
              onClick={() => onNodeSelect("MultiProductListNode")}>
              <FaBoxOpen className="text-green-700 text-2xl" />
            </button>
            <span className="mt-2 text-gray-700">Multiple Product</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PopupProductModal;
