import React, { useState, useEffect } from "react";
import { Handle, Position, useReactFlow } from "reactflow";
import { RiDeleteBin5Line } from "react-icons/ri";
import { FaListAlt } from "react-icons/fa";
import { message } from "antd";

const hfStyle = {
  backgroundColor: "white",
  top: 8,
  padding: 12,
  borderStyle: "solid",
  borderColor: "green",
  borderWidth: 5,
};

const hfStylebutton = {
  backgroundColor: "transparent", // Ensures a truly transparent background
  top: 145,
  right: -70,
  padding: 8,
  borderStyle: "solid",
  borderColor: "#008000", // Light green color for the border (adjust as needed)
  borderWidth: 4, // Adjust the border width to your preference
  width: 100,
  height: 10,
  borderRadius: 10,
};

function InteractiveList({ id, data }) {
  const replayheader = data.replayheader || "";
  const replaybody = data.replaybody || "";
  const replayfooter = data.replayFooter || "";
  const replaybuttontext = data.replaybuttontext || "";

  console.log(
    "all open ",
    replayheader,
    replaybody,
    replayfooter,
    replaybuttontext
  );
  const [headerText, setHeaderText] = useState(replayheader);
  const [bodyText, setBodyText] = useState(replaybody);
  const [footerText, setFooterText] = useState(replayfooter);
  const [sections, setSections] = useState([
    {
      title: "",
      rows: [{ id: "row_0", title: "", description: "" }],
      subsections: [
        {
          title: "",
          rows: [{ id: "subrow_0", title: "", description: "" }],
        },
      ],
    },
  ]);

  const [buttonText, setButtonText] = useState(replaybuttontext);
  const { setNodes } = useReactFlow();
  const [show, setShow] = useState(false);

  // Generate dynamic JSON payload
  const generateMessagePayload = () => {
    const payload = {
      messaging_product: "whatsapp",
      recipient_type: "individual",
      to: "mobile_no", // Replace with actual phone number
      type: "interactive",
      interactive: {
        type: "list",
        header: {
          type: "text",
          text: headerText,
        },
        body: {
          text: bodyText,
        },
        footer: {
          text: footerText,
        },
        action: {
          sections: sections.map((section) => ({
            title: section.title,
            rows: section.rows.map((row) => ({
              id: row.id || `row_${Math.random()}`,
              title: row.title,
              description: row.description,
            })),
          })),
          button: buttonText,
        },
      },
    };
    return payload;
  };

  // Trigger change whenever relevant state updates
  const handleChange = () => {
    if (data.onChange) {
      const messagePayload = generateMessagePayload();
      data.onChange(id, "Interactivepayload", messagePayload);
    }
  };

  useEffect(() => {
    handleChange();
  }, [headerText, bodyText, footerText, sections, buttonText]);

  const handleAddSection = () => {
    if (sections.length < 10) {
      setSections((prevSections) => [
        ...prevSections,
        { title: "", rows: [{ id: `row_0`, title: "", description: "" }] },
      ]);
    }
  };

  const handleNodeDelete = () => {
    setNodes((nodes) => nodes.filter((node) => node.id !== id));

    if (data.onChange) {
      data.onChange(id, "Interactivepayload", null);
    }
  };

  const handleDeleteRow = (sectionIndex, rowIndex) => {
    if (rowIndex > 0) {
      const updatedSections = [...sections];
      updatedSections[sectionIndex].rows.splice(rowIndex, 1);
      setSections(updatedSections);
    }
  };

  const handleDeleteSection = (sectionIndex) => {
    if (sectionIndex > 0) {
      const updatedSections = sections.filter(
        (_, index) => index !== sectionIndex
      );
      setSections(updatedSections);
    }
  };

  const handleAddRow = (sectionIndex) => {
    const updatedSections = [...sections];
    if (updatedSections[sectionIndex].rows.length < 10) {
      updatedSections[sectionIndex].rows.push({
        id: `row_${updatedSections[sectionIndex].rows.length}`,
        title: "",
        description: "",
      });
      setSections(updatedSections);
    }
  };

  const isButtonTextValid = buttonText.length > 0 && buttonText.length <= 20;
  const isBodyTextValid = bodyText.length > 0 && bodyText.length <= 4096;
  const isSectionTitleValid = (title) => title.length > 0 && title.length <= 24;
  const isRowTitleValid = (title) => title.length > 0 && title.length <= 24;

  return (
    <div
      className="relative bg-[#ffffff] rounded-[45px] p-7 w-[900px] shadow-2xl group hover:border-solid hover:border-[4px] hover:border-green-600"
      onMouseOver={() => setShow(true)}
      onMouseOut={() => setShow(false)}>
      <Handle
        type="target"
        position={Position.Left}
        id="interactiveListMessages"
        className="ml[10px]"
        style={hfStyle}
      />

      {show && (
        <button
          onClick={handleNodeDelete}
          className="absolute right-2 top-0 text-black text-lg rounded-full p-6 bg-white shadow-2xl hover:text-red-800">
          <RiDeleteBin5Line
            className="text-gray-600 hover:text-red-500"
            style={{ fontSize: 50 }}
          />
        </button>
      )}

      <div className="flex bg-[#def7ec] border-l-[20px] border-green-500 text-green-500 rounded-[20px] mt-1 mb-2 p-4 w-full">
        <FaListAlt className="mt-5 text-4xl" />
        <h3 className="text-center text-[40px] mt-2 ml-4">
          Interactive List Message
        </h3>
      </div>

      <div className="bg-[#eae6df] border-[3px] border-solid border-green-600 rounded-[25px] p-3 mt-5">
        <label className="block mt-11 text-3xl font-semibold text-gray-700 mb-3">
          Header Text (Optional)
        </label>
        <input
          type="text"
          placeholder="Enter header text"
          value={headerText}
          onChange={(e) => setHeaderText(e.target.value)}
          className="text-center rounded-2xl p-10 text-3xl w-full"
        />

        <label className="block mt-11 text-3xl font-semibold text-gray-700 mb-3">
          {`Body Text (Required)`}
        </label>

        <textarea
          placeholder="Enter Body text
          Body text must be 4096 characters or less"
          value={bodyText}
          onChange={(e) => setBodyText(e.target.value)}
          className={`text-center rounded-2xl p-10 text-3xl w-full pt-28 `}
          rows={5}
          cols={50}
        />

        {bodyText.length > 4096 && (
          <p className="text-red-500">
            Body text must be 4096 characters or less.
          </p>
        )}

        <label className="block mt-11 text-3xl font-semibold text-gray-700 mb-3">
          Footer Text (Optional)
        </label>
        <input
          type="text"
          placeholder="Enter footer text"
          value={footerText}
          onChange={(e) => setFooterText(e.target.value)}
          className="text-center rounded-2xl p-10 text-3xl w-full"
        />

        <label className="block mt-11 text-3xl font-semibold text-gray-700 mb-3">
          {`Button Text (Required)`}
        </label>
        <input
          type="text"
          placeholder="Enter button text"
          value={buttonText}
          onChange={(e) => setButtonText(e.target.value)}
          className={`text-center rounded-2xl p-10 text-3xl w-full `}
        />

        {buttonText.length > 20 && (
          <p className="text-red-500">
            Button text must be 20 characters or less.
          </p>
        )}

        {sections.map((section, sectionIndex) => (
          <div key={sectionIndex} className="mt-4">
            <label className="block mt-11 mb-2 text-3xl font-semibold text-gray-700">
              {`Section  ${sectionIndex + 1} Title (Required)`}
            </label>
            <input
              type="text"
              placeholder="Section Title"
              value={section.title}
              onChange={(e) => {
                const updatedSections = [...sections];
                updatedSections[sectionIndex].title = e.target.value;
                setSections(updatedSections);
              }}
              className={`text-center rounded-2xl p-10 text-3xl w-full mb-2 ${
                !isSectionTitleValid(section.title) ? "border-red-500" : ""
              }`}
            />
            {section.title.length > 24 && (
              <p className="text-red-500">
                Section title must be 24 characters or less.
              </p>
            )}

            {section.rows.map((row, rowIndex) => (
              <div key={rowIndex} className="relative mt-4 flex items-center">
                <Handle
                  type="source"
                  position={Position.Right}
                  id={`row_handle_${sectionIndex}_${rowIndex}`}
                  style={hfStylebutton}
                />
                <div className="flex-1">
                  <div className="flex items-center">
                    <label className="block mt-11 mb-2 text-3xl font-semibold text-gray-700">
                      {`Row ${rowIndex + 1} Title (Required)`}
                    </label>
                    {rowIndex > 0 && (
                      <button
                        onClick={() => handleDeleteRow(sectionIndex, rowIndex)}
                        className="mt-10 text-end ml-[440px] " // Add a margin to space the icon from the label
                      >
                        <i className="fas fa-trash-alt text-red-500 text-3xl"></i>
                      </button>
                    )}
                  </div>
                  <input
                    type="text"
                    placeholder="Row Title"
                    value={row.title}
                    onChange={(e) => {
                      const updatedSections = [...sections];
                      updatedSections[sectionIndex].rows[rowIndex].title =
                        e.target.value;
                      setSections(updatedSections);
                    }}
                    className={`text-center rounded-2xl p-10 text-3xl w-full mb-2 ${
                      !isRowTitleValid(row.title) ? "border-red-500" : ""
                    }`}
                  />

                  {row.title.length > 24 && (
                    <p className="text-red-500">
                      Row title must be 24 characters or less.
                    </p>
                  )}

                  <input
                    type="text"
                    placeholder="Row Description"
                    value={row.description}
                    onChange={(e) => {
                      const updatedSections = [...sections];
                      updatedSections[sectionIndex].rows[rowIndex].description =
                        e.target.value;
                      setSections(updatedSections);
                    }}
                    className="text-center rounded-2xl p-10 text-3xl w-full"
                  />
                </div>
              </div>
            ))}

            <div className="flex justify-between mt-4">
              <button
                onClick={() => {
                  const currentTitle = sections[sectionIndex].rows[0].title;

                  if (!isRowTitleValid(currentTitle)) {
                    message.error("Atleast one row must be fill");
                    return;
                  }

                  handleAddRow(sectionIndex);
                }}
                className="bg-green-500 text-white text-3xl rounded-xl p-7 mr-4 mb-3 ">
                + Add Row
              </button>
              {sectionIndex > 0 && (
                <button
                  onClick={() => handleDeleteSection(sectionIndex)}
                  className="bg-red-500 text-white text-3xl rounded-xl p-7 mr-4 mb-3 ">
                  Delete Section
                </button>
              )}
            </div>
            <button
              onClick={handleAddSection}
              className="bg-blue-500 text-white text-3xl rounded-xl p-7 w-full"
              disabled={sections.length >= 10}>
              + Add Section
            </button>
          </div>
        ))}
      </div>
    </div>
  );
}

export default InteractiveList;
