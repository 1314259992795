import React, { useState, useEffect, useRef } from "react";
import { Handle, Position, useReactFlow } from "reactflow";
import {
  FaFileAlt,
  FaImage,
  FaVideo,
  FaFileAudio,
  FaFacebookMessenger,
} from "react-icons/fa";
import { RiDeleteBin5Line } from "react-icons/ri";
import { message } from "antd";
import "reactflow/dist/style.css";
import { FaMobileScreenButton } from "react-icons/fa6";
import { uploadMedia } from "../api/MediaID";

const hfStyle = {
  backgroundColor: "white",
  top: 8,
  padding: 12,
  borderStyle: "solid",
  borderColor: "green",
  borderWidth: 5,
};

// Define file type and size limits
const FILE_VALIDATIONS = {
  image: {
    "image/jpeg": { maxSize: 5 * 1024 * 1024, ext: ".jpeg" },
    "image/png": { maxSize: 5 * 1024 * 1024, ext: ".png" },
  },
  video: {
    "video/3gp": { maxSize: 16 * 1024 * 1024, ext: ".3gp" },
    "video/mp4": { maxSize: 16 * 1024 * 1024, ext: ".mp4" },
  },
  audio: {
    "audio/aac": { maxSize: 16 * 1024 * 1024, ext: ".aac" },
    "audio/amr": { maxSize: 16 * 1024 * 1024, ext: ".amr" },
    "audio/mpeg": { maxSize: 16 * 1024 * 1024, ext: ".mp3" },
    "audio/mp4": { maxSize: 16 * 1024 * 1024, ext: ".m4a" },
    "audio/ogg": { maxSize: 16 * 1024 * 1024, ext: ".ogg" },
  },
  document: {
    "text/plain": { maxSize: 100 * 1024 * 1024, ext: ".txt" },
    "application/vnd.ms-excel": { maxSize: 100 * 1024 * 1024, ext: ".xls" },
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": {
      maxSize: 100 * 1024 * 1024,
      ext: ".xlsx",
    },
    "application/msword": { maxSize: 100 * 1024 * 1024, ext: ".doc" },
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document": {
      maxSize: 100 * 1024 * 1024,
      ext: ".docx",
    },
    "application/vnd.ms-powerpoint": {
      maxSize: 100 * 1024 * 1024,
      ext: ".ppt",
    },
    "application/vnd.openxmlformats-officedocument.presentationml.presentation":
      { maxSize: 100 * 1024 * 1024, ext: ".pptx" },
    "application/pdf": { maxSize: 100 * 1024 * 1024, ext: ".pdf" },
  },
};

function AllMedia({ id, data }) {
  const [type, setType] = useState("image");
  const [bodyText, setBodyText] = useState("");
  const { setNodes } = useReactFlow();
  const fileInputRef = useRef(null);
  const [file, setFile] = useState(null);
  const [show, setShow] = useState(false);
  const [mediaId, setMediaId] = useState("");
  const [error, setError] = useState("");

  const handleTypeChange = (e) => {
    setType(e.target.value);
    setFile(null); // Reset the file input for new type
    setError(""); // Clear any previous error messages
  };

  console.log("media id ", mediaId);
  const generateWhatsAppJSON = () => {
    const baseJSON = {
      messaging_product: "whatsapp",
      recipient_type: "individual",
      to: "mobile_no", // Replace with actual recipient number
    };

    // Construct the media part based on the type
    const mediaJSON =
      type === "image"
        ? { type: "image", image: { id: mediaId, caption: bodyText } }
        : type === "document" && file
        ? {
            type: "document",
            document: {
              id: mediaId,
              caption: bodyText,
              filename: file ? file.name : "",
            },
          }
        : type === "video" && file
        ? { type: "video", video: { id: mediaId, caption: bodyText } }
        : type === "audio" && file
        ? { type: "audio", audio: { id: mediaId } }
        : null;

    // Merge mediaJSON if it exists
    if (mediaJSON) {
      Object.assign(baseJSON, mediaJSON);
    }

    return baseJSON;
  };

  const handleChange = () => {
    if (data.onChange) {
      const messagePayload = generateWhatsAppJSON();
      data.onChange(id, "allMediapayload", messagePayload);
    }
  };

  // Effect to notify parent about text changes
  useEffect(() => {
    handleChange();
  }, [bodyText, id, data]);

  const handleInputChange = (e) => {
    const newText = e.target.value;

    // Validate character limit
    if (newText.length > 4096) {
      setError("Maximum character limit is 4096.");
      return; // Prevent setting the text if limit exceeded
    }

    // Check if the text contains valid URLs (optional)
    const urlRegex = /(https?:\/\/[^\s]+)/g; // Basic URL regex
    if (newText && !urlRegex.test(newText) && newText.includes("http")) {
      setError("Please enter valid URLs."); // Error if it contains 'http' but not a valid URL
      return;
    } else {
      setError(""); // Clear error if input is valid
    }

    setBodyText(newText);
  };

  const handleDeleteNode = () => {
    setNodes((prevNodes) => prevNodes.filter((node) => node.id !== id));
    if (data.onChange) {
      data.onChange(id, "allMediapayload", null); // Notify parent of text updates
    }
  };

  const shows = () => setShow(true);
  const leave = () => setShow(false);

  const handleFileChange = async (event) => {
    const selectedFile = event.target.files[0];

    if (selectedFile && validateFile(selectedFile)) {
      setFile(selectedFile);
      try {
        const uploadedMediaId = await uploadMedia(selectedFile);
        if (uploadedMediaId) {
          setMediaId(uploadedMediaId);
          message.success("Media uploaded successfully");
        } else {
          throw new Error("Failed to retrieve media ID.");
        }
      } catch (error) {
        message.error(error.message);
        setMediaId(""); // Reset mediaId if upload fails
      }
    }
  };

  // Trigger handleChange when mediaId updates
  useEffect(() => {
    if (mediaId) {
      handleChange();
    }
  }, [mediaId]);

  const validateFile = (selectedFile) => {
    const validTypes = FILE_VALIDATIONS[type];

    // Check file type
    if (!validTypes[selectedFile.type]) {
      setError(
        `Invalid file type. Accepted types: ${Object.keys(validTypes).join(
          ", "
        )}`
      );
      return false;
    }

    // Check file size
    const { maxSize } = validTypes[selectedFile.type];
    if (selectedFile.size > maxSize) {
      setError(`File size exceeds the limit of ${maxSize / (1024 * 1024)} MB.`);
      return false;
    }

    setError(""); // Clear error if validation passes
    return true;
  };

  return (
    <div
      className="relative bg-[#ffffff] rounded-[45px] p-7 w-[800px] shadow-2xl group hover:border-solid hover:border-[4px] hover:border-green-600"
      onMouseOver={shows}
      onMouseOut={leave}>
      <Handle
        type="target"
        position={Position.Left}
        id="media"
        style={hfStyle}
      />

      {show && (
        <button
          onClick={handleDeleteNode}
          className="absolute right-2 top-0 text-black text-lg rounded-full p-6 bg-white shadow-2xl hover:text-red-800">
          <RiDeleteBin5Line
            className="text-gray-600 hover:text-red-500"
            style={{ fontSize: 50 }}
          />
        </button>
      )}

      <div className="flex bg-[#def7ec] border-l-[20px] border-green-500 text-green-500 rounded-[20px] mt-1 mb-2 p-4 w-full">
        <FaMobileScreenButton className="mt-5 text-4xl" />
        <h3 className="text-center text-[40px] mt-2 ml-4">Media</h3>
      </div>

      <div className="bg-[#eae6df] border-[3px] border-solid border-green-600 rounded-[25px] p-3 mt-5">
        <label className="block p-4 text-3xl font-semibold text-gray-700 mb-3">
          Media Type
        </label>
        <select
          value={type}
          onChange={handleTypeChange}
          className="block w-full text-3xl p-9 rounded-lg border border-gray-300 shadow-sm focus:ring-2 focus:ring-green-600 focus:border-green-600">
          <option value="image" className="text-gray-700 text-[20px] pt-5">
            🖼️ Image
          </option>
          <option value="video" className="text-gray-700 text-[20px] pt-5">
            🎬 Video
          </option>
          <option value="document" className="text-gray-700 text-[20px] pt-5">
            📄 Document
          </option>
          <option value="audio" className="text-gray-700 text-[20px] pt-5">
            🎵 Audio
          </option>
        </select>

        <div className="mt-4">
          <input
            type="file"
            ref={fileInputRef}
            onChange={handleFileChange}
            accept={
              type === "image"
                ? ".jpeg,.jpg,.png"
                : type === "video"
                ? ".mp4,.3gp"
                : type === "document"
                ? ".pdf,.docx,.xlsx,.txt,.ppt,.pptx"
                : type === "audio"
                ? ".mp3,.wav,.aac,.m4a,.ogg,.amr"
                : ""
            }
            style={{ display: "none" }}
          />
          {file ? (
            type === "image" ? (
              <img
                src={URL.createObjectURL(file)}
                alt="Uploaded"
                className="rounded-2xl mx-auto"
                style={{ maxWidth: "100%", maxHeight: "100%" }}
              />
            ) : type === "video" ? (
              <video
                controls
                src={URL.createObjectURL(file)}
                className="rounded-2xl mx-auto"
                style={{ maxWidth: "100%", maxHeight: "100%" }}
              />
            ) : type === "audio" ? (
              <audio
                controls
                src={URL.createObjectURL(file)}
                className="rounded-2xl mx-auto"
                style={{ maxWidth: "100%" }}
              />
            ) : (
              <p className="mt-2 text-3xl text-gray-500">
                Document uploaded: {file.name}
              </p>
            )
          ) : (
            <button
              onClick={() => fileInputRef.current.click()}
              className="flex flex-col items-center justify-center p-4 border w-full h-[350px] border-gray-300 rounded-lg shadow-lg bg-white">
              {type === "image" && <FaImage className="text-6xl text-black" />}
              {type === "video" && <FaVideo className="text-6xl text-black" />}
              {type === "document" && (
                <FaFileAlt className="text-6xl text-black" />
              )}
              {type === "audio" && (
                <FaFileAudio className="text-6xl text-black" />
              )}
              <p className="mt-2 text-3xl text-gray-500">Upload a {type}</p>
            </button>
          )}

          {/* Caption input for specific media types */}
          {(type === "image" || type === "document" || type === "video") && (
            <div className="mt-4">
              <label className="block text-3xl font-semibold text-gray-700 mb-2">
                Caption (Optional)
              </label>
              <textarea
                type="text"
                value={bodyText}
                onChange={handleInputChange}
                className="block w-full text-3xl p-10 pt-20 rounded-lg border border-gray-300 shadow-sm focus:ring-2 focus:ring-green-600 focus:border-green-600"
                placeholder="Enter caption here"
              />
              {error && <p className="text-red-500">{error}</p>}{" "}
              {/* Display error if any */}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default AllMedia;
