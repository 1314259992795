import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Box,
  TextField,
  TableSortLabel,
  TablePagination,
  IconButton,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Edit, Delete } from "@mui/icons-material";
import CancelIcon from "@mui/icons-material/Cancel";
import Cookies from "js-cookie";
import { useLocation } from "react-router-dom";
import { Add } from "@mui/icons-material";
import { Search } from "@mui/icons-material";

const FlowTable = () => {
  const baseUrl = process.env.REACT_APP_API_URL;

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const Phonumbers = queryParams.get("phone_number_id");
  localStorage.setItem("userData", JSON.stringify(Phonumbers));
  console.log("phonumber", Phonumbers);
  const phoneNumberId = atob(Phonumbers);
  const [flows, setFlows] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortDirection, setSortDirection] = useState("asc");
  const [orderBy, setOrderBy] = useState("flow_name");
  const [parameter, setParameter] = useState({});
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const navigate = useNavigate();
  const handleFlow = () => {
    navigate("/Keyword-Automation", { state: { phoneNumberId } });
  };
  const handleUpdateFlow = () => {
    navigate("/Update", { state: { phoneNumberId } });
  };
  const handleFormCreate = () => {
    navigate("/FormCreate", { state: { phoneNumberId } });
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${baseUrl}/get-user-flows`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ phone_number_id: phoneNumberId }), // Convert body to JSON format
        });
        const data = await response.json(); // Parse response data

        setFlows(data.flow_data || []); // Set flows from the parsed data
      } catch (error) {
        console.error("Error fetching data:", error);
        setFlows([]);
      }
    };

    fetchData();
  }, []); // Dependency array can remain empty if phoneNumberId doesn't change

  const handleUpdate = async (flowId, phoneNumberId) => {
    const data = {
      flow_id: flowId,
      phone_number_id: phoneNumberId,
    };

    try {
      const response = await fetch(`${baseUrl}/get-user-flow-data-json`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }

      const responseBody = await response.json();

      navigate("/Update", {
        state: { responseData: responseBody },
      });
    } catch (error) {
      console.error("Error updating flow:", error);
      toast.error("Error updating flow");
    }
  };

  const handleDeleteClick = async (flowId) => {
    if (!window.confirm("Are you sure you want to delete this flow?")) {
      return;
    }

    try {
      const response = await fetch(`${baseUrl}/delete/${flowId}`, {
        method: "DELETE",
      });

      if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }

      setFlows((prevFlows) => prevFlows.filter((flow) => flow.id !== flowId));
      toast.success("Flow deleted successfully.");
    } catch (error) {
      console.error("Error deleting flow:", error);
      toast.error("Error deleting flow. Please try again later.");
    }
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && sortDirection === "asc";
    setSortDirection(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filteredFlows = flows.filter(
    (flow) =>
      flow.flow_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      flow.start_keyword.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const sortedFlows = [...filteredFlows].sort((a, b) => {
    if (a[orderBy] < b[orderBy]) return sortDirection === "asc" ? -1 : 1;
    if (a[orderBy] > b[orderBy]) return sortDirection === "asc" ? 1 : -1;
    return 0;
  });

  const paginatedFlows = sortedFlows.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  console.log("paginated flows", paginatedFlows);
  const handleClear = () => {
    setSearchTerm("");
  };

  return (
    <div className="bg-[#f0f6fb] min-h-[790px] py-4">
      <Box sx={{ margin: "10px", backgroundColor: "#ffff", borderRadius: 2 }}>
        <div className="flex items-center justify-between p-10">
          {/* Search Input */}
          <div className="relative flex items-center">
            <Search
              sx={{
                position: "absolute",
                left: "10px",
                top: "50%",
                transform: "translateY(-50%)",
                color: "#9ca3af", // Tailwind's gray-400 color
              }}
            />
            <input
              id="search-input"
              type="text"
              placeholder="Search..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="pl-10 p-2 bg-gray-100 border border-gray-300 rounded-lg focus:outline-none "
              aria-label="Search"
            />
          </div>
          {/* Create Flow Button */}
          <button
            onClick={handleFlow}
            className="bg-[#1d4c39] text-white px-8 py-3 hover:bg-green-800 rounded font-poppins flex items-center">
            <Add sx={{ marginRight: "8px", fontSize: "1.25rem" }} />
            Create Flow
          </button>
        </div>
        <TableContainer elevation={3}>
          <Table>
            <TableHead sx={{ backgroundColor: "#e8f5e9" }}>
              <TableRow>
                <TableCell sx={{ fontSize: "1rem", textAlign: "center" }}>
                  S.No
                </TableCell>{" "}
                {/* Serial Number Header */}
                <TableCell sx={{ fontSize: "1rem", textAlign: "center" }}>
                  <TableSortLabel
                    active={orderBy === "flow_name"}
                    direction={orderBy === "flow_name" ? sortDirection : "asc"}
                    onClick={() => handleRequestSort("flow_name")}
                    sx={{
                      fontSize: "1rem",
                      color: "#388e3c",
                      textAlign: "center",
                    }} // Green color
                  >
                    Flow Name
                  </TableSortLabel>
                </TableCell>
                <TableCell sx={{ fontSize: "1rem", textAlign: "center" }}>
                  <TableSortLabel
                    active={orderBy === "start_keyword"}
                    direction={
                      orderBy === "start_keyword" ? sortDirection : "asc"
                    }
                    onClick={() => handleRequestSort("start_keyword")}
                    sx={{
                      fontSize: "1rem",

                      textAlign: "center",
                    }} // Green color
                  >
                    Start Keyword
                  </TableSortLabel>
                </TableCell>
                <TableCell sx={{ fontSize: "1rem", textAlign: "center" }}>
                  <TableSortLabel
                    active={orderBy === "created_at"}
                    direction={orderBy === "created_at" ? sortDirection : "asc"}
                    onClick={() => handleRequestSort("created_at")}
                    sx={{
                      fontSize: "1rem",

                      textAlign: "center",
                    }} // Green color
                  >
                    Created at
                  </TableSortLabel>
                </TableCell>
                <TableCell sx={{ fontSize: "1rem", textAlign: "center" }}>
                  <TableSortLabel
                    active={orderBy === "updated_at"}
                    direction={orderBy === "updated_at" ? sortDirection : "asc"}
                    onClick={() => handleRequestSort("updated_at")}
                    sx={{
                      fontSize: "1rem",

                      textAlign: "center",
                    }} // Green color
                  >
                    Updated at
                  </TableSortLabel>
                </TableCell>
                <TableCell sx={{ fontSize: "1rem" }}>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedFlows.length > 0 ? (
                paginatedFlows.map((flow, index) => (
                  <TableRow key={flow.id}>
                    <TableCell sx={{ fontSize: "1rem", textAlign: "center" }}>
                      {index + 1}
                    </TableCell>{" "}
                    {/* Serial Number */}
                    <TableCell sx={{ fontSize: "1rem", textAlign: "center" }}>
                      {flow.flow_name}
                    </TableCell>
                    <TableCell sx={{ fontSize: "1rem", textAlign: "center" }}>
                      {flow.start_keyword}
                    </TableCell>
                    <TableCell sx={{ fontSize: "1rem", textAlign: "center" }}>
                      {flow.created_at}
                    </TableCell>
                    <TableCell sx={{ fontSize: "1rem", textAlign: "center" }}>
                      {flow.updated_at}
                    </TableCell>
                    <TableCell sx={{ fontSize: "0.8rem" }}>
                      <IconButton
                        onClick={() =>
                          handleUpdate(flow.id, flow.phone_number_id)
                        }
                        sx={{
                          fontSize: "0.8rem",
                          color: "#fff",
                          backgroundColor: "#388e3c",
                          "&:hover": {
                            backgroundColor: "#2c6c2f",
                          },
                          mr: 1,
                          padding: "6px",
                        }}>
                        <Edit />
                      </IconButton>

                      <IconButton
                        onClick={() => handleDeleteClick(flow.id)}
                        sx={{
                          fontSize: "0.8rem",
                          backgroundColor: "#d32f2f",
                          color: "#fff",
                          "&:hover": {
                            backgroundColor: "#b71c1c",
                          },
                          padding: "6px",
                        }}>
                        <Delete />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={5} // Update colSpan to account for the new S.No column
                    align="center"
                    sx={{ fontSize: "1rem" }}>
                    No flows available
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredFlows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{ fontSize: "1rem" }}
        />
        <ToastContainer />
      </Box>
    </div>
  );
};

export default FlowTable;
